body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px !important;
    color: #333;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-nav.menu-blue {
    background-color: #199FE3;
    border-bottom: 1px solid #e9e9e9;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
}

.container {
    padding-right: 15px!important;
    padding-left: 15px!important;
    margin-right: auto!important;
    margin-left: auto!important;
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 20px;
    border: 1px solid transparent;
}

#basic-navbar-nav > div > a {
    padding: 0px 15px;
    line-height: 40px;
    display: block;
    color: #fff!important;
    font-family: 'TheSansArabic', sans-serif;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    text-decoration: none!important;
    outline: none;
}


.top-logo {
    display: flex;
    justify-content: center;
    width: 100%;
}

.top-logo > a > img {
    width: 100%;
}

.navbar-toggler {
    height: 100%;
    background-color: transparent;
    border: 0;
    color: #e9e9e9;
}

.main-nav {
    font-family: "TheSansArabic", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 12px;
    margin-bottom: 0px;
    position: relative;
    width: 100%;
    z-index: 1030;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

.box {
    animation-duration: 2s;
    transform-origin: bottom;
}

.bounce-7 {
    animation-name: bounce-7;
    animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}
@keyframes bounce-7 {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-10px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
}

@media (min-width: 992px) {
    .main-nav {
        text-align: center;
        height: 90px;
    }

    .basic-navbar-nav {
        float: none;
        display: inline-block !important;
    }

    .navbar-expand-lg .navbar-collapse {
        justify-content: center;
    }

    #basic-navbar-nav > div > a {
        line-height: 90px;
    }
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px;
    }

    .mrg-horizon-100 {
        margin-left: 100px !important;
        margin-right: 100px !important
    }

}

@media (max-width: 991px) {
    .main-nav {
        height: auto;
    }

    .main-nav .navbar-header {
        text-align: center;
        width: 100%;
        margin-right: 0px!important;
        margin-left: 0px!important;
    }

    .mrg-horizon-100 {
        margin-left: 10px !important;
        margin-right: 10px !important;
    }
}


/*---------------------------------------------------------------------------
  Project: Boutiqaat
  Version: 1.0
----------------------------------------------------------------------------*/


< link rel="preload"href="TheSansArabic-Bold.woff2" as="font"type="font/woff2" crossorigin >
    /*****************************************************
    *****************  2. General CSS  *******************
    *****************************************************/
body {
    font-family: "TheSansArabic", Marcellus, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.2px;
    line-height: 1.8em;
    color: #000;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    padding: 0 !important;
}

.main-content-wrapper {
    position: relative;
    z-index: 2;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}


/*===============================
 =======   Typograpgy  ======
*===============================*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "TheSansArabic", Marcellus, Helvetica, sans-serif;
    text-transform: uppercase;
    color: #3e3e3e;
    line-height: 1.5
}

h1 {
    font-size: 21px;
    letter-spacing: Ù  .5px;
}

h2 {
    font-size: 18px!important;
    letter-spacing: Ù  .5px;
}

h3 {
    font-size: 16px;
    letter-spacing: Ù  .5px;
}

h4 {
    font-size: 13px;
    letter-spacing: Ù  .5px;
}

h5 {
    font-size: 12px;
    letter-spacing: Ù  .5px;
}

h6 {
    font-size: 10px;
    letter-spacing: Ù  .5px;
}

p,
span {
    letter-spacing: 0.5px;
    line-height: 26px;
}

.blockquote {
    padding: 10px 30px 5px;
    font-style: italic;
    font-size: 15px;
    border-left: 3px solid #ccc;
}

.blockquote.right {
    border-right: 3px solid #ccc;
    border-left: 0px
}

.vertical-text-left,
.vertical-text-right {
    position: absolute;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
}

.vertical-text-left {
    left: -30px
}

.vertical-text-right {
    right: -30px
}

.dropcap {
    display: block;
    float: left;
    font-size: 35px;
    line-height: 32px;
    margin: 5px 15px 0 0;
    color: #3e3e3e;
}

.dropcap.box {
    color: #ececec;
    background-color: #212121;
    padding: 10px 15px;
}

.dropcap.border {
    border: 3px solid #3e3e3e;
    padding: 10px 15px;
}

.dropcap.border-round {
    border: 3px solid #3e3e3e;
    padding: 10px 15px;
    border-radius: 50px
}

ul {
    padding: 0px
}

ul li {
    list-style: none
}

.list {
    position: relative
}

.list li {
    list-style: disc
}

.list.number li {
    list-style-type: decimal;
}

.list.circle li {
    list-style-type: circle;
}

.list.upper-latin li {
    list-style-type: upper-latin;
}

.list li {
    list-style: none;
    padding-left: 30px;
}

.list.icon-star li,
.list.icon-check li,
.list.icon-angle li,
.list.icon-angle-circle li {
    padding-bottom: 15px
}

.list.icon-star li:before,
.list.icon-check li:before,
.list.icon-angle li:before,
.list.icon-angle-circle li:before {
    font-family: 'themify';
    position: absolute;
    left: 0px
}

.list.icon-star li:before {
    content: "\e60a";
}

.list.icon-check li:before {
    content: "\e64c";
}

.list.icon-angle li:before {
    content: "\e649";
}

.list.icon-angle-circle li:before {
    content: "\e65d";
}

.monserrat {
    font-family: "TheSansArabic", Helvetica, sans-serif !important
}

.opensans {
    font-family: "Open Sans", Helvetica, sans-serif !important;
}

.droid-serif {
    font-family: 'TheSansArabic', serif !important;
}

.droid-serif-italic {
    font-family: 'TheSansArabic', serif !important;
    font-style: italic !important
}

.text-white {
    color: #ececec !important
}

.text-gray {
    color: #8b8b8b !important
}

.text-dark {
    color: #ffffff !important;
}


/*Heading*/

.heading,
.heading-left,
.heading-right {
    font-family: "TheSansArabic", Helvetica, sans-serif;
}

.heading {
    text-align: center;
    position: relative;
}

.heading-left {
    text-align: left;
    position: relative
}

.heading-right {
    text-align: right;
    position: relative
}

.sub-heading {
    font-family: 'TheSansArabic', serif;
    letter-spacing: 2px;
    margin-top: 25px;
}

.sub-heading-left {
    font-family: 'TheSansArabic', serif;
    font-style: italic;
    letter-spacing: 1px;
    margin-top: 45px;
    text-align: left
}

.sub-heading-right {
    font-family: 'TheSansArabic', serif;
    font-style: italic;
    letter-spacing: 1px;
    margin-top: 45px;
    text-align: right
}

.sub-heading-center {
    font-family: 'TheSansArabic', serif;
    letter-spacing: 1px;
    margin-top: 30px!important;
    text-align: center;
}

.bg-dark .heading:before,
.bg-dark .heading:after,
.bg-dark .heading-left:before,
.bg-dark .heading-left:after,
.bg-dark .heading-right:before,
.bg-dark .heading-right:after,
.parallax-overlay-dark .heading:before,
.parallax-overlay-dark .heading:after,
.parallax-overlay-dark .heading-left:before,
.parallax-overlay-dark .heading-left:after,
.parallax-overlay-dark .heading-right:before,
.parallax-overlay-dark .heading-right:after {
    background-color: #ececec
}

.heading:before,
.heading:after,
.heading-left:before,
.heading-left:after,
.heading-right:before,
.heading-right:after {
    content: '';
    position: absolute;
    background-color: #3e3e3e;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
}

.heading:before,
.heading-left:before,
.heading-right:before {
    width: 30px;
    bottom: -14px;
}

.heading:after,
.heading-left:after,
.heading-right:after {
    width: 40px;
    bottom: -19px;
}

.heading:before,
.heading:after {
    left: 0;
    right: 0;
}

.heading-left:before,
.heading-left:after {
    left: 0;
}

.heading-right:before,
.heading-right:after {
    right: 0;
}

.heading-alt-style {
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 300
}

h1.heading-alt-style {
    letter-spacing: 10px;
}

h2.heading-alt-style {
    letter-spacing: 8px;
}

h3.heading-alt-style {
    letter-spacing: 8px;
}

h4.heading-alt-style {
    letter-spacing: 6px;
}

h5.heading-alt-style {
    letter-spacing: 4px;
}


/*Font Size*/

.font-size-8 {
    font-size: 8px !important
}

.font-size-9 {
    font-size: 9px !important
}

.font-size-10 {
    font-size: 10px !important
}

.font-size-11 {
    font-size: 11px !important
}

.font-size-12 {
    font-size: 12px !important
}

.font-size-13 {
    font-size: 13px !important
}

.font-size-14 {
    font-size: 14px !important
}

.font-size-15 {
    font-size: 15px !important
}

.font-size-16 {
    font-size: 16px !important
}

.font-size-17 {
    font-size: 17px !important
}

.font-size-18 {
    font-size: 18px !important
}

.font-size-19 {
    font-size: 19px !important
}

.font-size-20 {
    font-size: 20px !important
}

.font-size-21 {
    font-size: 21px !important
}

.font-size-22 {
    font-size: 22px !important
}

.font-size-23 {
    font-size: 23px !important
}

.font-size-24 {
    font-size: 24px !important
}

.font-size-25 {
    font-size: 25px !important
}

.font-size-26 {
    font-size: 26px !important
}

.font-size-27 {
    font-size: 27px !important
}

.font-size-28 {
    font-size: 28px !important
}

.font-size-29 {
    font-size: 29px !important
}

.font-size-30 {
    font-size: 30px !important
}

.font-size-35 {
    font-size: 35px !important
}

.font-size-40 {
    font-size: 40px !important
}

.font-size-45 {
    font-size: 45px !important
}

.font-size-50 {
    font-size: 50px !important
}

.font-size-55 {
    font-size: 55px !important
}

.font-size-60 {
    font-size: 60px !important
}

.font-size-65 {
    font-size: 65px !important
}

.font-size-70 {
    font-size: 70px !important
}

.font-size-80 {
    font-size: 80px !important
}

.font-size-90 {
    font-size: 90px !important
}

.font-size-100 {
    font-size: 100px !important
}

.font-size-110 {
    font-size: 110px !important
}

.font-size-120 {
    font-size: 120px !important
}

.font-size-130 {
    font-size: 130px !important
}

.font-size-140 {
    font-size: 140px !important
}

.font-size-150 {
    font-size: 150px !important
}


/*Font Weight*/

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: bold !important
}


/*Text Transform*/

.text-xform-none {
    text-transform: none !important
}

.uppercase {
    text-transform: uppercase !important
}

.lowercase {
    text-transform: lowercase !important
}

.capitalize {
    text-transform: capitalize !important
}


/*Font Style*/

.font-style-n {
    font-style: normal
}

.font-style-i {
    font-style: italic
}

.font-style-o {
    font-style: oblique
}


/*Letter-Spacing*/

.ls-0 {
    letter-spacing: 0px !important
}

.ls-1 {
    letter-spacing: 1px !important
}

.ls-1-5 {
    letter-spacing: 1.5px !important
}

.ls-2 {
    letter-spacing: 2px !important
}

.ls-2-5 {
    letter-spacing: 2.5px !important
}

.ls-3 {
    letter-spacing: 3px !important
}

.ls-3-5 {
    letter-spacing: 3.5px !important
}

.ls-4 {
    letter-spacing: 4px !important
}

.ls-4-5 {
    letter-spacing: 4.5px !important
}

.ls-5 {
    letter-spacing: 5px !important
}

.ls-5-5 {
    letter-spacing: 5.5px !important
}

.ls-6 {
    letter-spacing: 6px !important
}

.ls-6-5 {
    letter-spacing: 6.5px !important
}

.ls-7 {
    letter-spacing: 7px !important
}

.ls-7-5 {
    letter-spacing: 7.5px !important
}

.ls-8 {
    letter-spacing: 8px !important
}

.ls-8-5 {
    letter-spacing: 8.5px !important
}

.ls-9 {
    letter-spacing: 9px !important
}

.ls-9-5 {
    letter-spacing: 9.5px !important
}

.ls-10 {
    letter-spacing: 10px !important
}

.ls-12 {
    letter-spacing: 12px !important
}

.ls-15 {
    letter-spacing: 15px !important
}

.ls-17 {
    letter-spacing: 17px !important
}

.ls-20 {
    letter-spacing: 20px !important
}


/*Line-Height*/

.lh-1 {
    line-height: 1
}

.lh-1-1 {
    line-height: 1.1
}

.lh-1-2 {
    line-height: 1.2
}

.lh-1-3 {
    line-height: 1.3
}

.lh-1-4 {
    line-height: 1.4
}

.lh-1-5 {
    line-height: 1.5
}

.lh-1-6 {
    line-height: 1.6
}

.lh-1-7 {
    line-height: 1.7
}

.lh-1-8 {
    line-height: 1.8
}

.lh-1-9 {
    line-height: 1.9
}

.lh-2 {
    line-height: 2
}


/*===============================
 ========    Spacing  ========
*===============================*/


/*Display*/

.display-table {
    display: table
}

.block {
    display: block
}

.inline-block {
    display: inline-block
}


/*Margin*/

.no-margin {
    margin: 0 !important
}

.no-margin-top {
    margin-top: 0 !important
}

.no-margin-btm {
    margin-bottom: 0 !important
}

.no-margin-right {
    margin-right: 0 !important
}

.no-margin-left {
    margin-left: 0 !important
}

.no-margin-vertical {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.no-margin-horizon {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.mrg-horizon-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

.mrg-left-auto {
    margin-left: auto !important;
}

.mrg-right-auto {
    margin-right: auto !important;
}

.mrg-vertical-5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important
}

.mrg-vertical-10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important
}

.mrg-vertical-15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important
}

.mrg-vertical-20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important
}

.mrg-vertical-25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important
}

.mrg-vertical-30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important
}

.mrg-vertical-35 {
    margin-top: 35px !important;
    margin-bottom: 35px !important
}

.mrg-vertical-40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important
}

.mrg-vertical-45 {
    margin-top: 45px !important;
    margin-bottom: 45px !important
}

.mrg-vertical-50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important
}

.mrg-vertical-55 {
    margin-top: 55px !important;
    margin-bottom: 55px !important
}

.mrg-vertical-60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important
}

.mrg-vertical-65 {
    margin-top: 65px !important;
    margin-bottom: 65px !important
}

.mrg-vertical-70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important
}

.mrg-vertical-75 {
    margin-top: 75px !important;
    margin-bottom: 75px !important
}

.mrg-vertical-80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important
}

.mrg-vertical-85 {
    margin-top: 85px !important;
    margin-bottom: 85px !important
}

.mrg-vertical-90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important
}

.mrg-vertical-95 {
    margin-top: 95px !important;
    margin-bottom: 95px !important
}

.mrg-vertical-100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important
}

.mrg-vertical-105 {
    margin-top: 105px !important;
    margin-bottom: 105px !important
}

.mrg-vertical-110 {
    margin-top: 110px !important;
    margin-bottom: 110px !important
}

.mrg-vertical-115 {
    margin-top: 115px !important;
    margin-bottom: 115px !important
}

.mrg-vertical-120 {
    margin-top: 120px !important;
    margin-bottom: 120px !important
}

.mrg-vertical-125 {
    margin-top: 125px !important;
    margin-bottom: 125px !important
}

.mrg-horizon-5 {
    margin-left: 5px !important;
    margin-right: 5px !important
}

.mrg-horizon-10 {
    margin-left: 10px !important;
    margin-right: 10px !important
}

.mrg-horizon-15 {
    margin-left: 15px !important;
    margin-right: 15px !important
}

.mrg-horizon-20 {
    margin-left: 20px !important;
    margin-right: 20px !important
}

.mrg-horizon-25 {
    margin-left: 25px !important;
    margin-right: 25px !important
}

.mrg-horizon-30 {
    margin-left: 30px !important;
    margin-right: 30px !important
}

.mrg-horizon-35 {
    margin-left: 35px !important;
    margin-right: 35px !important
}

.mrg-horizon-40 {
    margin-left: 40px !important;
    margin-right: 40px !important
}

.mrg-horizon-45 {
    margin-left: 45px !important;
    margin-right: 45px !important
}

.mrg-horizon-50 {
    margin-left: 50px !important;
    margin-right: 50px !important
}

.mrg-horizon-55 {
    margin-left: 55px !important;
    margin-right: 55px !important
}

.mrg-horizon-60 {
    margin-left: 60px !important;
    margin-right: 60px !important
}

.mrg-horizon-65 {
    margin-left: 65px !important;
    margin-right: 65px !important
}

.mrg-horizon-70 {
    margin-left: 70px !important;
    margin-right: 70px !important
}

.mrg-horizon-75 {
    margin-left: 75px !important;
    margin-right: 75px !important
}

.mrg-horizon-80 {
    margin-left: 80px !important;
    margin-right: 80px !important
}

.mrg-horizon-85 {
    margin-left: 85px !important;
    margin-right: 85px !important
}

.mrg-horizon-90 {
    margin-left: 90px !important;
    margin-right: 90px !important
}

.mrg-horizon-95 {
    margin-left: 95px !important;
    margin-right: 95px !important
}

.mrg-horizon-105 {
    margin-left: 105px !important;
    margin-right: 105px !important
}

.mrg-horizon-110 {
    margin-left: 110px !important;
    margin-right: 110px !important
}

.mrg-horizon-115 {
    margin-left: 115px !important;
    margin-right: 115px !important
}

.mrg-horizon-120 {
    margin-left: 120px !important;
    margin-right: 120px !important
}

.mrg-horizon-125 {
    margin-left: 125px !important;
    margin-right: 125px !important
}

.mrg-top-5 {
    margin-top: 5px !important
}

.mrg-top-10 {
    margin-top: 10px !important
}

.mrg-top-15 {
    margin-top: 15px !important
}

.mrg-top-20 {
    margin-top: 20px !important
}

.mrg-top-25 {
    margin-top: 25px !important
}

.mrg-top-30 {
    margin-top: 30px !important
}

.mrg-top-35 {
    margin-top: 35px !important
}

.mrg-top-40 {
    margin-top: 40px !important
}

.mrg-top-45 {
    margin-top: 45px !important
}

.mrg-top-50 {
    margin-top: 50px !important
}

.mrg-top-55 {
    margin-top: 55px !important
}

.mrg-top-60 {
    margin-top: 60px !important
}

.mrg-top-65 {
    margin-top: 65px !important
}

.mrg-top-70 {
    margin-top: 70px !important
}

.mrg-top-75 {
    margin-top: 75px !important
}

.mrg-top-80 {
    margin-top: 80px !important
}

.mrg-top-85 {
    margin-top: 85px !important
}

.mrg-top-90 {
    margin-top: 90px !important
}

.mrg-top-95 {
    margin-top: 95px !important
}

.mrg-top-100 {
    margin-top: 100px !important
}

.mrg-top-105 {
    margin-top: 105px !important
}

.mrg-top-110 {
    margin-top: 110px !important
}

.mrg-top-115 {
    margin-top: 115px !important
}

.mrg-top-120 {
    margin-top: 120px !important
}

.mrg-top-125 {
    margin-top: 125px !important
}

.mrg-btm-5 {
    margin-bottom: 5px !important
}

.mrg-btm-10 {
    margin-bottom: 10px !important
}

.mrg-btm-15 {
    margin-bottom: 15px !important
}

.mrg-btm-20 {
    margin-bottom: 20px !important
}

.mrg-btm-25 {
    margin-bottom: 25px !important
}

.mrg-btm-30 {
    margin-bottom: 30px !important
}

.mrg-btm-35 {
    margin-bottom: 35px !important
}

.mrg-btm-40 {
    margin-bottom: 40px !important
}

.mrg-btm-45 {
    margin-bottom: 45px !important
}

.mrg-btm-50 {
    margin-bottom: 50px !important
}

.mrg-btm-55 {
    margin-bottom: 55px !important
}

.mrg-btm-60 {
    margin-bottom: 60px !important
}

.mrg-btm-65 {
    margin-bottom: 65px !important
}

.mrg-btm-70 {
    margin-bottom: 70px !important
}

.mrg-btm-75 {
    margin-bottom: 75px !important
}

.mrg-btm-80 {
    margin-bottom: 80px !important
}

.mrg-btm-85 {
    margin-bottom: 85px !important
}

.mrg-btm-90 {
    margin-bottom: 90px !important
}

.mrg-btm-95 {
    margin-bottom: 95px !important
}

.mrg-btm-100 {
    margin-bottom: 100px !important
}

.mrg-btm-105 {
    margin-bottom: 105px !important
}

.mrg-btm-110 {
    margin-bottom: 110px !important
}

.mrg-btm-115 {
    margin-bottom: 115px !important
}

.mrg-btm-120 {
    margin-bottom: 120px !important
}

.mrg-btm-125 {
    margin-bottom: 125px !important
}

.mrg-left-5 {
    margin-left: 5px !important
}

.mrg-left-10 {
    margin-left: 10px !important
}

.mrg-left-15 {
    margin-left: 15px !important
}

.mrg-left-20 {
    margin-left: 20px !important
}

.mrg-left-25 {
    margin-left: 25px !important
}

.mrg-left-30 {
    margin-left: 30px !important
}

.mrg-left-35 {
    margin-left: 35px !important
}

.mrg-left-40 {
    margin-left: 40px !important
}

.mrg-left-45 {
    margin-left: 45px !important
}

.mrg-left-50 {
    margin-left: 50px !important
}

.mrg-left-55 {
    margin-left: 55px !important
}

.mrg-left-60 {
    margin-left: 60px !important
}

.mrg-left-65 {
    margin-left: 65px !important
}

.mrg-left-70 {
    margin-left: 70px !important
}

.mrg-left-75 {
    margin-left: 75px !important
}

.mrg-left-80 {
    margin-left: 80px !important
}

.mrg-left-85 {
    margin-left: 85px !important
}

.mrg-left-90 {
    margin-left: 90px !important
}

.mrg-left-95 {
    margin-left: 95px !important
}

.mrg-left-100 {
    margin-left: 100px !important
}

.mrg-left-105 {
    margin-left: 105px !important
}

.mrg-left-110 {
    margin-left: 110px !important
}

.mrg-left-115 {
    margin-left: 115px !important
}

.mrg-left-120 {
    margin-left: 120px !important
}

.mrg-left-125 {
    margin-left: 125px !important
}

.mrg-right-5 {
    margin-right: 5px !important
}

.mrg-right-10 {
    margin-right: 10px !important
}

.mrg-right-15 {
    margin-right: 15px !important
}

.mrg-right-20 {
    margin-right: 20px !important
}

.mrg-right-25 {
    margin-right: 25px !important
}

.mrg-right-30 {
    margin-right: 30px !important
}

.mrg-right-35 {
    margin-right: 35px !important
}

.mrg-right-40 {
    margin-right: 40px !important
}

.mrg-right-45 {
    margin-right: 45px !important
}

.mrg-right-50 {
    margin-right: 50px !important
}

.mrg-right-55 {
    margin-right: 55px !important
}

.mrg-right-60 {
    margin-right: 60px !important
}

.mrg-right-65 {
    margin-right: 65px !important
}

.mrg-right-70 {
    margin-right: 70px !important
}

.mrg-right-75 {
    margin-right: 75px !important
}

.mrg-right-80 {
    margin-right: 80px !important
}

.mrg-right-85 {
    margin-right: 85px !important
}

.mrg-right-90 {
    margin-right: 90px !important
}

.mrg-right-95 {
    margin-right: 95px !important
}

.mrg-right-100 {
    margin-right: 100px !important
}

.mrg-right-105 {
    margin-right: 105px !important
}

.mrg-right-110 {
    margin-right: 110px !important
}

.mrg-right-115 {
    margin-right: 115px !important
}

.mrg-right-120 {
    margin-right: 120px !important
}

.mrg-right-125 {
    margin-right: 125px !important
}


/*Padding*/

.no-padding {
    padding: 0 !important
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-btm {
    padding-bottom: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-vertical {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.no-padding-horizon {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.pdd-vertical-5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important
}

.pdd-vertical-10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important
}

.pdd-vertical-15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important
}

.pdd-vertical-20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important
}

.pdd-vertical-25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important
}

.pdd-vertical-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important
}

.pdd-vertical-35 {
    padding-top: 35px !important;
    padding-bottom: 35px !important
}

.pdd-vertical-40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important
}

.pdd-vertical-45 {
    padding-top: 45px !important;
    padding-bottom: 45px !important
}

.pdd-vertical-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important
}

.pdd-vertical-55 {
    padding-top: 55px !important;
    padding-bottom: 55px !important
}

.pdd-vertical-60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important
}

.pdd-vertical-65 {
    padding-top: 65px !important;
    padding-bottom: 65px !important
}

.pdd-vertical-70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important
}

.pdd-vertical-75 {
    padding-top: 75px !important;
    padding-bottom: 75px !important
}

.pdd-vertical-80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important
}

.pdd-vertical-85 {
    padding-top: 85px !important;
    padding-bottom: 85px !important
}

.pdd-vertical-90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important
}

.pdd-vertical-95 {
    padding-top: 95px !important;
    padding-bottom: 95px !important
}

.pdd-vertical-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important
}

.pdd-vertical-105 {
    padding-top: 105px !important;
    padding-bottom: 105px !important
}

.pdd-vertical-110 {
    padding-top: 110px !important;
    padding-bottom: 110px !important
}

.pdd-vertical-115 {
    padding-top: 115px !important;
    padding-bottom: 115px !important
}

.pdd-vertical-120 {
    padding-top: 120px !important;
    padding-bottom: 120px !important
}

.pdd-vertical-125 {
    padding-top: 125px !important;
    padding-bottom: 125px !important
}

.pdd-horizon-5 {
    padding-left: 5px !important;
    padding-right: 5px !important
}

.pdd-horizon-10 {
    padding-left: 10px !important;
    padding-right: 10px !important
}

.pdd-horizon-15 {
    padding-left: 15px !important;
    padding-right: 15px !important
}

.pdd-horizon-20 {
    padding-left: 20px !important;
    padding-right: 20px !important
}

.pdd-horizon-25 {
    padding-left: 25px !important;
    padding-right: 25px !important
}

.pdd-horizon-30 {
    padding-left: 30px !important;
    padding-right: 30px !important
}

.pdd-horizon-35 {
    padding-left: 35px !important;
    padding-right: 35px !important
}

.pdd-horizon-40 {
    padding-left: 40px !important;
    padding-right: 40px !important
}

.pdd-horizon-45 {
    padding-left: 45px !important;
    padding-right: 45px !important
}

.pdd-horizon-50 {
    padding-left: 50px !important;
    padding-right: 50px !important
}

.pdd-horizon-55 {
    padding-left: 55px !important;
    padding-right: 55px !important
}

.pdd-horizon-60 {
    padding-left: 60px !important;
    padding-right: 60px !important
}

.pdd-horizon-65 {
    padding-left: 65px !important;
    padding-right: 65px !important
}

.pdd-horizon-70 {
    padding-left: 70px !important;
    padding-right: 70px !important
}

.pdd-horizon-75 {
    padding-left: 75px !important;
    padding-right: 75px !important
}

.pdd-horizon-80 {
    padding-left: 80px !important;
    padding-right: 80px !important
}

.pdd-horizon-85 {
    padding-left: 85px !important;
    padding-right: 85px !important
}

.pdd-horizon-90 {
    padding-left: 90px !important;
    padding-right: 90px !important
}

.pdd-horizon-95 {
    padding-left: 95px !important;
    padding-right: 95px !important
}

.pdd-horizon-100 {
    padding-left: 100px !important;
    padding-right: 100px !important
}

.pdd-horizon-105 {
    padding-left: 105px !important;
    padding-right: 105px !important
}

.pdd-horizon-110 {
    padding-left: 110px !important;
    padding-right: 110px !important
}

.pdd-horizon-115 {
    padding-left: 115px !important;
    padding-right: 115px !important
}

.pdd-horizon-120 {
    padding-left: 120px !important;
    padding-right: 120px !important
}

.pdd-horizon-125 {
    padding-left: 125px !important;
    padding-right: 125px !important
}

.pdd-top-5 {
    padding-top: 5px !important
}

.pdd-top-10 {
    padding-top: 10px !important
}

.pdd-top-15 {
    padding-top: 15px !important
}

.pdd-top-20 {
    padding-top: 20px !important
}

.pdd-top-25 {
    padding-top: 25px !important
}

.pdd-top-30 {
    padding-top: 30px !important
}

.pdd-top-35 {
    padding-top: 35px !important
}

.pdd-top-40 {
    padding-top: 40px !important
}

.pdd-top-45 {
    padding-top: 45px !important
}

.pdd-top-50 {
    padding-top: 50px !important
}

.pdd-top-55 {
    margin-top: 55px !important
}

.pdd-top-60 {
    padding-top: 60px !important
}

.pdd-top-65 {
    padding-top: 65px !important
}

.pdd-top-70 {
    padding-top: 70px !important
}

.pdd-top-75 {
    padding-top: 75px !important
}

.pdd-top-80 {
    padding-top: 80px !important
}

.pdd-top-85 {
    padding-top: 85px !important
}

.pdd-top-90 {
    padding-top: 90px !important
}

.pdd-top-95 {
    padding-top: 95px !important
}

.pdd-top-100 {
    padding-top: 100px !important
}

.pdd-top-105 {
    padding-top: 105px !important
}

.pdd-top-110 {
    padding-top: 110px !important
}

.pdd-top-115 {
    padding-top: 115px !important
}

.pdd-top-120 {
    padding-top: 120px !important
}

.pdd-top-125 {
    padding-top: 125px !important
}

.pdd-btm-5 {
    padding-bottom: 5px !important
}

.pdd-btm-10 {
    padding-bottom: 10px !important
}

.pdd-btm-15 {
    padding-bottom: 15px !important
}

.pdd-btm-20 {
    padding-bottom: 20px !important
}

.pdd-btm-25 {
    padding-bottom: 25px !important
}

.pdd-btm-30 {
    padding-bottom: 30px !important
}

.pdd-btm-35 {
    padding-bottom: 35px !important
}

.pdd-btm-40 {
    padding-bottom: 40px !important
}

.pdd-btm-45 {
    padding-bottom: 45px !important
}

.pdd-btm-50 {
    padding-bottom: 50px !important
}

.pdd-btm-55 {
    margin-bottom: 55px !important
}

.pdd-btm-60 {
    padding-bottom: 60px !important
}

.pdd-btm-65 {
    padding-bottom: 65px !important
}

.pdd-btm-70 {
    padding-bottom: 70px !important
}

.pdd-btm-75 {
    padding-bottom: 75px !important
}

.pdd-btm-80 {
    padding-bottom: 80px !important
}

.pdd-btm-85 {
    padding-bottom: 85px !important
}

.pdd-btm-90 {
    padding-bottom: 90px !important
}

.pdd-btm-95 {
    padding-bottom: 95px !important
}

.pdd-btm-100 {
    padding-bottom: 100px !important
}

.pdd-btm-105 {
    padding-bottom: 105px !important
}

.pdd-btm-110 {
    padding-bottom: 110px !important
}

.pdd-btm-115 {
    padding-bottom: 115px !important
}

.pdd-btm-120 {
    margin-bottom: 120px !important
}

.pdd-btm-125 {
    padding-bottom: 125px !important
}

.pdd-left-5 {
    padding-left: 5px !important
}

.pdd-left-10 {
    padding-left: 10px !important
}

.pdd-left-15 {
    padding-left: 15px !important
}

.pdd-left-20 {
    padding-left: 20px !important
}

.pdd-left-25 {
    padding-left: 25px !important
}

.pdd-left-30 {
    padding-left: 30px !important
}

.pdd-left-35 {
    padding-left: 35px !important
}

.pdd-left-40 {
    padding-left: 40px !important
}

.pdd-left-45 {
    padding-left: 45px !important
}

.pdd-left-50 {
    padding-left: 50px !important
}

.pdd-left-55 {
    margin-left: 55px !important
}

.pdd-left-60 {
    padding-left: 60px !important
}

.pdd-left-65 {
    padding-left: 65px !important
}

.pdd-left-70 {
    padding-left: 70px !important
}

.pdd-left-75 {
    padding-left: 75px !important
}

.pdd-left-80 {
    padding-left: 80px !important
}

.pdd-left-85 {
    padding-left: 85px !important
}

.pdd-left-90 {
    padding-left: 90px !important
}

.pdd-left-95 {
    padding-left: 95px !important
}

.pdd-left-100 {
    padding-left: 100px !important
}

.pdd-left-105 {
    padding-left: 105px !important
}

.pdd-left-110 {
    padding-left: 110px !important
}

.pdd-left-115 {
    padding-left: 115px !important
}

.pdd-left-120 {
    margin-left: 120px !important
}

.pdd-left-125 {
    padding-left: 125px !important
}

.pdd-right-5 {
    padding-right: 5px !important
}

.pdd-right-10 {
    padding-right: 10px !important
}

.pdd-right-15 {
    padding-right: 15px !important
}

.pdd-right-20 {
    padding-right: 20px !important
}

.pdd-right-25 {
    padding-right: 25px !important
}

.pdd-right-30 {
    padding-right: 30px !important
}

.pdd-right-35 {
    padding-right: 35px !important
}

.pdd-right-40 {
    padding-right: 40px !important
}

.pdd-right-45 {
    padding-right: 45px !important
}

.pdd-right-50 {
    padding-right: 50px !important
}

.pdd-right-55 {
    margin-right: 55px !important
}

.pdd-right-60 {
    padding-right: 60px !important
}

.pdd-right-65 {
    padding-right: 65px !important
}

.pdd-right-70 {
    padding-right: 70px !important
}

.pdd-right-75 {
    padding-right: 75px !important
}

.pdd-right-80 {
    padding-right: 80px !important
}

.pdd-right-85 {
    padding-right: 85px !important
}

.pdd-right-90 {
    padding-right: 90px !important
}

.pdd-right-95 {
    padding-right: 95px !important
}

.pdd-right-100 {
    padding-right: 100px !important
}

.pdd-right-105 {
    padding-right: 105px !important
}

.pdd-left-110 {
    padding-left: 110px !important
}

.pdd-right-115 {
    padding-right: 115px !important
}

.pdd-right-120 {
    margin-right: 120px !important
}

.pdd-right-125 {
    padding-right: 125px !important
}


/*Alignment*/

.pull-left {
    float: left !important
}

.pull-right {
    float: right !important
}

.vertical-align-center {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}


/*Positioning*/

.relative {
    position: relative !important
}

.absolute {
    position: absolute
}

.fixed {
    position: fixed
}


/*View height*/

.vh-30 {
    height: 30vh
}

.vh-50 {
    height: 50vh
}

.vh-70 {
    height: 70vh
}

.vh-100 {
    height: 100vh
}


/*Width*/

.width-10 {
    width: 10%
}

.width-20 {
    width: 20%
}

.width-30 {
    width: 30%
}

.width-40 {
    width: 40%
}

.width-50 {
    width: 50%
}

.width-60 {
    width: 60%
}

.width-70 {
    width: 70%
}

.width-80 {
    width: 80%
}

.width-90 {
    width: 90%
}

.width-100 {
    width: 100%
}


/*Height*/

.max-height {
    height: 100%
}


/*===============================
 =========   Border  =========
*===============================*/

.border {
    border: 1px solid #e9e9e9;
    border-style: solid !important
}

.border.thick-md {
    border-width: 5px !important
}

.border.top {
    border: 0px;
    border-top: 1px solid #e9e9e9 !important;
}

.border.left {
    border: 0px;
    border-left: 1px solid #e9e9e9 !important;
}

.border.right {
    border: 0px;
    border-right: 1px solid #e9e9e9 !important;
}

.border.btm {
    border: 0px;
    border-bottom: 1px solid #e9e9e9 !important;
}

.border.border-dark {
    border-color: #3e3e3e !important
}

.no-border {
    border: 0px !important;
    border-style: none !important
}


/*****************************************************
*******************  3. Layout  *********************
*****************************************************/


/*===============================
 ========  3.1 Frame  =========
*===============================*/

.screen-frame {
    position: relative;
    float: left;
    width: 100%;
    overflow: hidden;
}

.screen-frame .bar-top,
.screen-frame .bar-btm,
.screen-frame .bar-left,
.screen-frame .bar-right {
    background-color: #fff;
    width: 100%;
    position: fixed;
    z-index: 99;
    overflow: hidden;
}

.screen-frame .bar-left,
.screen-frame .bar-right {
    z-index: 99999
}

.screen-frame .bar-top,
.screen-frame .bar-btm {
    left: 0;
    right: 0;
}

.screen-frame .bar-top {
    height: 90px;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

.screen-frame .bar-top.after-scroll {
    height: 75px !important
}

.screen-frame.static .bar-top,
.screen-frame.static .bar-top.after-scroll {
    height: 30px !important
}

.screen-frame .bar-btm {
    height: 30px;
}

.screen-frame .bar-left,
.screen-frame .bar-right {
    width: 30px;
    bottom: 0;
    top: 0;
}

.screen-frame .bar-top {
    top: 0px
}

.screen-frame .bar-btm {
    bottom: 0px
}

.screen-frame .bar-left {
    left: 0px
}

.screen-frame .bar-right {
    right: 0px
}


/*===============================
 ========  3.2 Section  =========
*===============================*/

.section {
    padding-top: 60px;
    padding-bottom: 100px;
    flex:1 ;
}

.section-2 {
    padding-top: 100px;
    padding-bottom: 100px
}

.section-3 {
    padding-top: 75px;
    padding-bottom: 75px
}

.section-4 {
    padding-top: 40px;
    padding-bottom: 40px
}

.section-5 {
    padding-top: 70px;
    padding-bottom: 40px;
    height: 110px
}

.section-no-btm {
    padding-top: 160px
}

section {
    border-bottom: 1px solid #ececec;
}


/*===============================
 ======= Background  =======
*===============================*/

.bg-light-gray {
    background-color: #FAFBFC
}

.bg-gray {
    background-color: #f8f8f8;
}

.bg-white {
    background-color: #fff;
}

.bg-transparent {
    background-color: transparent
}

.bg-transparent-light {
    background-color: rgba(255, 255, 255, 0.75)
}

.bg-transparent-dark {
    background-color: rgba(0, 0, 0, 0.7)
}

.bg-dark {
    background-color: #212121;
}

.bg-dark-gray {
    background-color: #2e3036;
}

.bg-dark h1,
.bg-dark h2,
.bg-dark h3,
.bg-dark h4,
.bg-dark h5,
.bg-dark h6,
.bg-transparent-dark h1,
.bg-transparent-dark h2,
.bg-transparent-dark h3,
.bg-transparent-dark h4,
.bg-transparent-dark h5,
.bg-transparent-dark h6,
.bg-transparent-dark p,
.bg-transparent-dark span {
    color: #ececec
}

.bg-dark p,
.bg-dark span,
.bg-dark a,
.bg-transparent-dark p,
.bg-transparent-dark span,
.bg-transparent-dark a {
    color: #9e9e9e!important;
}

.bg {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.img-bg-nav-shop {
}


/*===============================
 ========  3.4 Parallax  ========
*===============================*/

.parallax {
    background-attachment: fixed;
    background-size: cover;
}

.parallax-overlay-dark,
.parallax-overlay-light,
.parallax-overlay-dark > div,
.parallax-overlay-light > div {
    position: relative
}

.parallax-overlay-dark:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
}

.parallax-overlay-gradient-dark:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0.51) 26%, rgba(62, 62, 62, 0.66) 49%, rgba(33, 33, 33, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(26%, rgba(153, 153, 153, 0.51)), color-stop(49%, rgba(62, 62, 62, 0.66)), color-stop(100%, rgba(33, 33, 33, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0.51) 26%, rgba(62, 62, 62, 0.66) 49%, rgba(33, 33, 33, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0.51) 26%, rgba(62, 62, 62, 0.66) 49%, rgba(33, 33, 33, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0.51) 26%, rgba(62, 62, 62, 0.66) 49%, rgba(33, 33, 33, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(153, 153, 153, 0.51) 26%, rgba(62, 62, 62, 0.66) 49%, rgba(33, 33, 33, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#212121', GradientType=0);
}

.parallax-overlay-light:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.75);;
}

.parallax-overlay-gradient-light:before {
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0);
    background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.51) 26%, rgba(255, 255, 255, 0.66) 49%, rgba(255, 255, 255, 1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(26%, rgba(255, 255, 255, 0.51)), color-stop(49%, rgba(255, 255, 255, 0.66)), color-stop(100%, rgba(255, 255, 255, 1)));
    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.51) 26%, rgba(255, 255, 255, 0.66) 49%, rgba(255, 255, 255, 1) 100%);
    background: -o-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.51) 26%, rgba(255, 255, 255, 0.66) 49%, rgba(255, 255, 255, 1) 100%);
    background: -ms-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.51) 26%, rgba(255, 255, 255, 0.66) 49%, rgba(255, 255, 255, 1) 100%);
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.51) 26%, rgba(255, 255, 255, 0.66) 49%, rgba(255, 255, 255, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0);
}

.parallax-overlay-dark,
.parallax-overlay-dark h1,
.parallax-overlay-dark h2,
.parallax-overlay-dark h3,
.parallax-overlay-dark h4,
.parallax-overlay-dark h5,
.parallax-overlay-dark h6 {
    color: #ececec;
}

.video-parallax {
    position: relative;
    overflow: hidden;
    min-height: 500px
}


/*===============================
 =======   Split box  ========
*===============================*/

.split-box {
    padding: 0px;
    position: relative;
}

.split-box .image-container {
    overflow: hidden;
    position: absolute;
    height: 100%;
    padding: 0px;
    top: 0px;
}

.split-box .content {
    padding: 80px 50px;
    margin-left: -15px;
    margin-right: -15px;
    position: relative
}

.split-box .background-holder {
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-size: cover !important;
    z-index: 0;
    background-position: 50% 50% !important;
}

.split-box .background-holder.has-content {
    display: table
}

.split-box .background-holder.has-content .content {
    display: table-cell;
    vertical-align: middle;
}

.split-box .bordered-left {
    border-left: 10px solid #fff
}

.split-box .bordered-right {
    border-right: 10px solid #fff
}

.bg-gray .split-box .bordered-left {
    border-left: 10px solid #f8f8f8
}

.bg-gray .split-box .bordered-right {
    border-right: 10px solid #f8f8f8
}

.bg-dark .split-box .bordered-left {
    border-left: 10px solid #212121
}

.bg-dark .split-box .bordered-right {
    border-right: 10px solid #212121
}

.border-box .box-wrapper {
    position: relative;
    border-right: 1px solid #ececec;
    padding: 30px 0px;
}

.border-box .box-wrapper .fact-icons {
    font-size: 35px
}

.border-box .box-wrappe {
    position: relative;
    border-right: 1px solid #ececec;
    padding: 30px 0px;
}

.border-box .box-wrappe .fact-icons {
    font-size: 325px
}

/*****************************************************
******************   Preloader  ********************
*****************************************************/

.page-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: block;
    z-index: 99999999;
}

.loader span {
    font-family: "TheSansArabic", Helvetica, sans-serif;
    position: absolute;
    font-weight: normal;
    top: 52%;
    font-size: 45px;
    letter-spacing: 15px;
    margin-left: -100px;
    left: 50%;
    margin-top: 20px;
    color: #212121;
}


/*Preloader 1*/

.loader-style-1 {
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    font-size: 30px;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 0.5s 0.1s;
    transition: transform 0.5s 0.1s;
    perspective: 9999px;
    color: #3e3e3e;
    margin: -40px 0 0 -40px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -37px;
    top: 65px;
    opacity: 1;
}

.loader-style-1.panelLoad {
    z-index: 11;
    top: 45%;
    margin-top: -60px;
    -webkit-animation: panel 2.2s infinite forwards;
    animation: panel 2.2s infinite forwards;
}

.loader-style-1.panelLoad .cube-face {
    color: black;
    box-shadow: inset 0 0 0 1px #222, 0 0 1px 1px #222;
}

.loader-style-1 .cube-face {
    width: inherit;
    height: inherit;
    position: absolute;
    background: white;
    box-shadow: inset 0 0 0 1px #3e3e3e, 0 0 1px 1px #3e3e3e;
    opacity: 1;
    font-family: Arial, Helvetica, sans-serif;
}

.loader-style-1 .cube-face-front {
    transform: translate3d(0, 0, 40px);
    -webkit-transform: translate3d(0, 0, 40px);
    font-size: 30px;
}

.loader-style-1 .cube-face-back {
    -webkit-transform: rotateY(180deg) translate3d(0, 0, 40px);
    transform: rotateY(180deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-left {
    -webkit-transform: rotateY(-90deg) translate3d(0, 0, 40px);
    transform: rotateY(-90deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-right {
    -webkit-transform: rotateY(90deg) translate3d(0, 0, 40px);
    transform: rotateY(90deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-top {
    -webkit-transform: rotateX(90deg) translate3d(0, 0, 40px);
    transform: rotateX(90deg) translate3d(0, 0, 40px);
}

.loader-style-1 .cube-face-bottom {
    -webkit-transform: rotateX(-90deg) translate3d(0, 0, 40px);
    transform: rotateX(-90deg) translate3d(0, 0, 40px);
}

@-webkit-keyframes panel {
    0% {
        -webkit-transform: rotateY(0deg) rotateZ(0deg);
    }
    20% {
        -webkit-transform: rotateY(90deg) rotateZ(0deg);
    }
    40% {
        -webkit-transform: rotateX(45deg) rotateZ(45deg);
    }
    60% {
        -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
    }
    80% {
        -webkit-transform: rotateX(310deg) rotateZ(230deg);
    }
    100% {
        -webkit-transform: rotateX(360deg) rotateZ(360deg);
    }
}

@keyframes panel {
    0% {
        -webkit-transform: rotateY(0deg) rotateZ(0deg);
        transform: rotateY(0deg) rotateZ(0deg);
    }
    20% {
        -webkit-transform: rotateY(90deg) rotateZ(0deg);
        transform: rotateY(90deg) rotateZ(0deg);
    }
    40% {
        -webkit-transform: rotateX(45deg) rotateZ(45deg);
        transform: rotateX(45deg) rotateZ(45deg);
    }
    60% {
        -webkit-transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
        transform: rotateX(90deg) rotateY(180deg) rotateX(90deg);
    }
    80% {
        -webkit-transform: rotateX(310deg) rotateZ(230deg);
        transform: rotateX(310deg) rotateZ(230deg);
    }
    100% {
        -webkit-transform: rotateX(360deg) rotateZ(360deg);
        transform: rotateX(360deg) rotateZ(360deg);
    }
}


/*Preloader 2*/


/*===============================
 ==========    Logo  ==========
*===============================*/


.brand-logo a {
    display: block;
}


.navbar-brand {
    padding: 0px;
}

.menu-brand {
    padding: 0px;
    margin: -57px 4px -35px -6px
}


/*===============================
 ========  5.2 Main Menu  =======
*===============================*/

.menu-sticky {
    height: 75px !important;
    position: fixed !important;
    left: 0px;
    right: 0px;
    top: 0;
}

.menu-sticky .inner-nav > ul > li > a {
    line-height: 75px !important;
}

.menu-sticky .brand-logo img,
.menu-sticky .navbar-toggle {
    height: 75px !important
}

.navbar-toggle {
    float: right;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.navbar-toggle button {
    height: 100%;
    background-color: transparent;
    border: 0;
}

.menu-transparent {
    background-color: transparent !important;
    position: fixed !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-bottom: 0px !important
}

.menu-transparent.push-top {
    margin-top: 30px
}

.main-nav.js-transparent {
    -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
}

.main-nav.menu-dark {
    background-color: #232323;
    -webkit-box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);
}

.main-nav.menu-dark .inner-nav > ul > li > a,
.main-nav.menu-dark .navbar-toggle i {
    color: #ececec;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.main-nav.menu-dark .inner-nav > ul > li > a:hover,
.main-nav.menu-dark .inner-nav > ul > li > a:focus,
.main-nav.menu-dark .navbar-toggle i:hover,
.main-nav.op-nav.menu-dark .inner-nav > ul > li.active > a {
    color: #af9433
}

.main-nav.menu-light {
    background-color: #fff;
    border-bottom: 1px solid #e9e9e9
}

.main-nav.menu-light .inner-nav > ul > li > a,
.main-nav.menu-light .navbar-toggle i {
    color: #000;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.main-nav.menu-light .inner-nav > ul > li > a:hover,
.main-nav.menu-light .inner-nav > ul > li > a:focus,
.main-nav.menu-light .navbar-toggle i:hover,
.main-nav.op-nav.menu-light .inner-nav > ul > li.active > a {
    color: #000
}


.main-nav .inner-nav > ul > li > a {
    padding: 0px 15px;
    line-height: 90px;
    display: block
}

.navbar-toggle {
    margin: 0;
    padding: 0;
    height: 90px
}

.navbar-toggle .icon-bar {
    background-color: #3e3e3e
}

.main-nav.menu-blue {
    background-color: #199FE3;
    border-bottom: 1px solid #e9e9e9
}

.main-nav.menu-blue .inner-nav > ul > li > a,
.main-nav.menu-blue .navbar-toggle i {
    color: #fff;
    font-family: 'TheSansArabic', sans-serif;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
}

.main-nav.menu-blue .inner-nav > ul > li > a:hover,
.main-nav.menu-blue .inner-nav > ul > li > a:focus,
.main-nav.menu-blue .navbar-toggle i:hover,
.main-nav.op-nav.menu-blue .inner-nav > ul > li.active > a {
    color: #fff;
    font-family: 'TheSansArabic', sans-serif;

}

/*===============================
 ========  5.3 Dropdown  =======
*===============================*/

.main-nav .dropdown-menu {
    background-color: rgba(18, 18, 18, 0.9);
    font-size: 11px;
    letter-spacing: 0.5px;
    border-radius: 0px;
    border: 0px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    display: block;
}

.main-nav .dropdown-header {
    color: #ececec;
    padding: 10px 0px;
    border-bottom: 1px solid #444;
    width: auto;
    float: left;
    letter-spacing: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.main-nav .dropdown-menu li a {
    color: #888;
    display: inline-block;
    width: 100%;
    padding: 10px 0px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
}

.main-nav .dropdown:hover > ul.dropdown-menu {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
}

.main-nav .dropdown-menu li a:hover,
.main-nav .dropdown-menu li a:focus {
    color: #ececec;
    padding-left: 5px;
    background-color: transparent;
}

.custom-collapse .dropdown > a > i {
    font-size: 8px;
    opacity: 0;
    transition: all 0.1s ease;
    -webkit-transition: all 0.1s ease;
}

.custom-collapse .dropdown > a:hover > i {
    opacity: 1;
}

.nav .mega-menu {
    position: static;
}

.mega-menu .dropdown-menu {
    left: 0;
    right: 0;
    margin-left: auto;
    padding: 20px 30px 25px 30px;
    border: 0;
    top: auto
}

.mega-menu .dropdown-menu li {
    min-width: 160px;
}

.mega-menu .dropdown-menu li a i {
    font-size: 14px;
    padding-right: 10px;
}

.mega-menu .shop-heading {
    border-bottom: 1px solid #777;
    padding: 0px 5px;
    padding-bottom: 5px;
    margin-left: 10px;
    width: auto;
    float: left;
}

.submenu {
    position: relative;
}

.submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
}

.submenu:hover > .dropdown-menu {
    margin-top: 0;
}

.submenu > a:after {
    font-family: "themify";
    display: block;
    content: "\e649";
    position: absolute;
    right: 25px;
    top: 0;
    margin-top: 17px;
    font-size: 7px;
}

.classic-dropdown .dropdown-menu {
    min-width: 200px;
}

.classic-dropdown .dropdown-menu li a {
    margin: 5px 30px;
}


/*===============================
 ========  5.4 Right nav  =======
*===============================*/

.right-nav {
    font-size: 16px;
}

.right-nav > ul {
    padding: 0px
}

.right-nav ul li {
    float: left;
    list-style: none
}

#search-trigger {
    background-color: transparent;
    border: 0;
    display: block
}

#search {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
}

#search.active {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
}

#search .input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 125px;
    margin: auto;
}

#search .input .search {
    float: left;
    width: calc(100% - 125px);
    height: 85px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 40px;
    letter-spacing: 1.3px;
    line-height: 85px;
    text-indent: 20px;
    border-bottom: 1px solid #ddd;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 300
}

#search .input .search::-webkit-input-placeholder {
    color: #ccc;
    font-family: "Open Sans", Helvetica, sans-serif;
    font-weight: 300;
    font-size: 40px
}

#search .input .submit {
    cursor: pointer;
    float: left;
    width: 125px;
    height: 85px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 50px;
    color: #bbb;
    text-align: center;
    line-height: 85px;
    border-bottom: 1px solid #ddd
}

#search #close {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 30px;
    width: 125px;
    height: 95px;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    background: transparent;
    font-size: 40px;
    color: #bbb;
    text-align: center;
    line-height: 95px;
}

.cart-dropdown {
    position: relative
}

.cart-dropdown .notice-num {
    position: absolute;
    right: 3px;
    color: #ececec;
    background-color: #af9433;
    font-size: 10px;
    padding: 0px 0px;
    border-radius: 50px;
    height: 20px;
    display: inline-block;
    top: 20px;
    line-height: 19px;
    padding-right: 5px;
    padding-left: 7px;
}

.cart-dropdown ul {
    left: -280px;
    width: 330px;
    padding: 15px 20px 35px 20px;
    margin-top: 0
}

.cart-dropdown ul .cart-list,
.cart-dropdown ul .cart-bottom {
    width: 100%
}

.cart-dropdown ul .cart-bottom p {
    font-size: 12px;
    letter-spacing: 1px
}

.cart-dropdown ul .cart-list .cart-item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding-top: 15px;
    border-bottom: 1px solid #3e3e3e;
}

.cart-dropdown ul .cart-list .cart-item .cart-image {
    width: 80px;
    float: left;
    margin-right: 15px;
}

.cart-dropdown ul .cart-list .cart-item .cart-info {
    float: left
}

.cart-dropdown ul .cart-list .cart-item .cart-info a {
    padding: 0px;
    color: #ececec;
    font-size: 12px;
    letter-spacing: 1px;
    display: block
}

.cart-dropdown ul .cart-list .cart-item .cart-info span {
    width: 100%;
    display: inline-block;
    color: #999;
    font-weight: normal;
    font-size: 12px
}

.cart-dropdown ul .cart-list .cart-item .cart-info .delete {
    position: absolute;
    right: 0;
    top: 20px;
    padding: 15px;
    width: auto;
    font-size: 20px;
    color: #999;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

.cart-dropdown ul .cart-list .cart-item .cart-info .delete:hover {
    color: #ececec
}

.cart-dropdown ul .cart-bottom .button-group a {
    width: 45%;
    padding: 10px 15px;
    font-size: 10px;
}


/*===============================
 ========   Top Logo  ========
*===============================*/

.top-logo {
    width: 100%;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

.top-logo.push-top {
    margin-top: -90px;
}

.main-nav.has-top-logo .navbar-header {
    text-align: center
}

.top-logo-transparent {
    position: fixed;
    z-index: 1023;
}

@media (min-width: 992px) {
    .main-nav.has-top-logo {
        text-align: center
    }

    .main-nav.has-top-logo .inner-nav,
    .main-nav.has-top-logo .navbar-header {
        float: none;
        display: inline-block !important
    }
}


/*===============================
 ========  5.8 Top Menu  ========
*===============================*/

.top-nav {
    position: relative;
    z-index: 1031;
    font-weight: normal;
    font-size: 13px;
    transition: all 0.05s ease-in-out;
    -webkit-transition: all 0.05s ease-in-out;
}

.top-nav.push-top {
    margin-top: -40px
}

.top-nav.top-nav-transparent {
    position: fixed;
    width: 100%
}

.top-nav .topnav-item {
    line-height: 40px;
    display: inline-block;
}

.top-nav .topnav-item span,
.top-nav .topnav-item .dropdown a {
    padding: 0px 15px;
    line-height: 40px;
    display: inline-block;
}

.top-nav .topnav-item span a {
    display: inline-block
}

.top-nav.bg-dark .topnav-item.bordered,
.top-nav.bg-dark .topnav-item.bordered.pull-right {
    border-right: 1px solid #3e3e3e
}

.top-nav .topnav-item.bordered,
.top-nav .topnav-item.bordered.pull-right {
    border-right: 1px solid #e9e9e9
}

.top-nav .topnav-item .dropdown-menu {
    right: -16px;
    left: auto;
    box-shadow: none;
    border-radius: 0px;
    border: 1px solid #e9e9e9;
    background-color: #fff;
    margin: 0px;
    padding: 0px;
    min-width: 200px
}

.top-nav .topnav-item .dropdown-menu li a {
    line-height: 50px;
    display: inline-block;
    width: 100%;
    padding: 0px 20px;
    height: 100%;
}

.top-nav .topnav-item .dropdown-menu li.selected:after {
    position: absolute;
    content: "\e64c";
    right: 15px;
    top: 0px;
    font-family: 'themify';
}


/*===================================
 ======   Rainyday effect  =======
*==================================*/

.rainyday {
    overflow: hidden;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative
}

v
    /*==============================
     =======   Particles  =======
    *=============================*/
#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99
}


/*****************************************************
*******************    Headers **********************
*****************************************************/

.header {
    position: relative;
}

.header-lg {
    padding-top: 140px;
    padding-bottom: 80px
}

.header-md {
    padding-top: 80px;
    padding-bottom: 50px
}

.header-sm {
    padding-top: 50px;
    padding-bottom: 30px
}

.header .border {
    display: inline-block
}

.header .sub-heading {
    margin-top: auto;
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
}

.breadcrumb {
    background-color: transparent;
    border-radius: 0;
    margin-bottom: 0px
}

.breadcrumb ul li {
    font-weight: normal;
    padding: 0px 15px;
    position: relative;
    text-transform: capitalize;
    display: inline-block;
}

.breadcrumb ul li:first-child {
    padding-left: 0px
}

.breadcrumb ul li a {
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

.breadcrumb ul li:after {
    position: absolute;
    top: 0px;
    right: 0
}

.breadcrumb.slash ul li:after {
    content: '/';
}

.breadcrumb.arrow ul li:after {
    content: '\e649';
    right: -5px;
    font-family: 'themify';
    font-size: 8px
}

.breadcrumb.dotted ul li:after {
    content: '\e6e2';
    right: -6px;
    font-family: 'themify';
    font-size: 8px;
}

.breadcrumb.dash ul li:after {
    content: '-';
    right: -6px;
    font-family: 'themify';
    font-size: 8px;
}

.breadcrumb ul li:last-child:after {
    content: '';
}

.header.bg-dark .breadcrumb ul li a:hover,
.header.parallax-overlay-dark .breadcrumb ul li a:hover {
    color: #fff
}

.header.parallax-overlay-dark .breadcrumb ul li a,
.header.parallax-overlay-dark .sub-heading,
.header.bg-dark .sub-heading {
    color: #ececec
}

.header .breadcrumb ul li a:hover,
.header.parallax-overlay-light .breadcrumb ul li a:hover {
    color: #000
}

.header.parallax-overlay-light .breadcrumb ul li a,
.header.parallax-overlay-light .sub-heading {
    color: #000
}

.fs-slider,
.fs-img-parallax {
    overflow: hidden;
    position: relative;
    width: 100%
}

.fs-slider .carousel-inner .item,
.fs-img-parallax {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.fs-slider .carousel-inner,
.fs-slider .carousel-inner .item,
.fs-img-parallax {
    height: 100vh
}

.fw-slider .carousel-inner .item {
    height: 100%
}

.fw-slider .carousel-inner {
    height: 600px
}

.carousel-control.left,
.carousel-control.right,
.control-style-2.left,
.control-style-2.right {
    background: transparent;
}

.control-style-2.left i,
.control-style-2.right i {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    position: absolute;
    opacity: 0;
    font-size: 30px;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out
}

.control-style-2:hover.left i {
    left: 15%
}

.control-style-2:hover.right i {
    right: 15%
}

.fw-slider:hover .control-style-2.left i,
.fw-slider:hover .control-style-2.right i,
.fs-slider:hover .control-style-2.left i,
.fs-slider:hover .control-style-2.right i {
    opacity: 1
}

.control-style-2.left i {
    left: 20%

}

.control-style-2.right i {
    right: 20%
}


.carousel-control.control-style-2.left,
.carousel-control.control-style-2.right {
    cursor: auto
}

.carousel-fade .carousel-inner .item {
    opacity: 0;
    transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
    opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
    opacity: 1;
}

.carousel-fade .carousel-control {
    z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
    .carousel-fade .carousel-inner > .item.next,
    .carousel-fade .carousel-inner > .item.active.right {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .carousel-fade .carousel-inner > .item.prev,
    .carousel-fade .carousel-inner > .item.active.left {
        opacity: 0;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .carousel-fade .carousel-inner > .item.next.left,
    .carousel-fade .carousel-inner > .item.prev.right,
    .carousel-fade .carousel-inner > .item.active {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.fs-slider .carousel-control {
    z-index: 11;
    width: 35%;
}


/*****************************************************
*****************    Infographyy  *******************
*****************************************************/

.infography {
    padding: 10px
}


/*****************************************************
********************  Buttons ********************
*****************************************************/

a,
button,
button:hover,
button:focus {
    text-decoration: none!important;
    outline: none!important;
    color: #000!important;
}

a:hover,
a:focus {
    color: #af9433!important;
    text-decoration: none;
}

a:focus,
button:focus {
    outline: none;
    text-decoration: none;
}

button {
    background-color: transparent;
}

.btn {
    font-family: "TheSansArabic", Helvetica, sans-serif;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 12px;
    margin-right: 10px;
    margin-bottom: 15px;
    letter-spacing: 2px;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
}

.btn.radius {
    border-radius: 5px
}

.play-btn {
    font-style: normal;
    display: inline-block;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s ease;
}

.play-btn:hover {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
}

.play-btn-dark {
    color: #212121
}

.play-btn-light {
    color: #ececec
}

.play-btn:before {
    font-family: 'FontAwesome';
    content: "\f144";
}


/*===============================
 =====   Button Style  =====
*===============================*/

.btn-style-1 {
    border: 1px solid #fdfdfd;
    color: #3e3e3e !important;
    background-color: #fdfdfd
}

.btn-style-1:hover {
    border: 1px solid #fdfdfd;
    color: #ececec !important;
    background-color: transparent
}

.btn-style-2 {
    border: 1px solid #212121;
    color: #ececec !important;
    background-color: #212121
}

.btn-style-2:hover {
    border: 1px solid #212121;
    color: #3e3e3e !important;
    background-color: transparent !important
}

.btn-style-3 {
    border: 1px solid #fdfdfd;
    color: #fcfcfc !important;
    background-color: transparent !important
}

.btn-style-3:hover {
    border: 1px solid #F8F8F8;
    color: #3e3e3e !important;
    background-color: #F8F8F8 !important
}

.btn-style-4 {
    border: 1px solid #212121;
    background-color: transparent;
    color: #212121 !important;
}

.btn-style-4:hover {
    border: 1px solid #212121;
    color: #ececec !important;
    background-color: #212121 !important
}

.btn-style-5 {
    border: 1px solid #af9433;
    color: #af9433 !important;
    background-color: transparent
}

.btn-style-5:hover {
    border: 1px solid #af9433;
    color: #ececec !important;
    background-color: #af9433
}

.btn-style-6 {
    background-color: #3e3e3e;
    color: #ececec
}

.btn-style-6:hover {
    background-color: #000;
    color: #ececec
}

.btn-style-7 {
    color: #000;
    font-size: 18px;
    padding: 18px 35px

}

.btn-style-7:hover {
    background-color: #232323;
    color: #af9433;
    padding: 18px 35px;
    font-size: 18px
}


/*===============================
 ======    Button Size  ======
*===============================*/

.btn-xsm {
    padding: 5px 15px;
    font-size: 9px;
}

.btn-sm {
    padding: 5px 20px;
}

.btn-md {
    padding: 10px 35px;;
}

.btn-lg {
    padding: 15px 35px;
}

.btn-icon {
    padding: 12px 15px;
    font-size: 14px
}

.btn-menu {
    padding: 12px 15px;
    font-size: 15px;
    font-weight: 400
}

.btn-icon.round {
    border-radius: 5px
}

.btn-icon.circle {
    border-radius: 50px
}

.btn-block {
    padding: 10px 0px;
    width: 100%;
    display: inline-block;
}


/*===============================
 ====    Social Buttons  ====
*===============================*/

.social-icon ul {
    display: inline-block
}

.social-icon ul li {
    float: left
}

.social-icon ul li a {
    margin-right: 10px;
    padding: 10px 12px;
    font-size: 12px
}

.social-icon ul li a {
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out
}

.social-icon ul li.box a {
    border: 1px solid #777;
}

.social-icon ul li.box-round a {
    border: 1px solid #777;
    border-radius: 5px;
}

.social-icon.dark ul li.box a,
.social-icon.dark ul li.box-round a,
.social-icon.light ul li.box:hover a,
.social-icon.light ul li.box-round:hover a {
    color: #000;
    background-color: #af9433
}

.social-icon.light ul li.box a,
.social-icon.light ul li.box-round a,
.social-icon.dark ul li.box:hover a,
.social-icon.dark ul li.box-round:hover a {
    color: #212121;
    background-color: #ececec
}

.facebook:hover {
    color: #3B579D
}

.facebook.border {
    border: 1px solid #3b579d;
    background-color: transparent;
    color: #3b579d
}

.twitter:hover {
    color: #2CAAE1
}

.twitter.border {
    border: 1px solid #2CAAE1;
    background-color: transparent;
    color: #2CAAE1
}

.instagram:hover {
    color: #5d4a3b
}

.instagram.border {
    border: 1px solid #5d4a3b;
    background-color: transparent;
    /* color: #5d4a3b */
}

.google:hover {
    color: #DA4835
}

.google.border {
    border: 1px solid #DA4835;
    background-color: transparent;
    color: #DA4835
}

.snapchat:hover {
    color: #fffc00
}

.snapchat.border {
    border: 1px solid #DA4835;
    background-color: transparent;
    color: #fffc00
}

.pinterest:hover {
    color: #c9181f
}

.pinterest.border {
    border: 1px solid #c9181f;
    background-color: transparent;
    color: #c9181f
}

.skype:hover {
    color: #00AAF1
}

.skype.border {
    border: 1px solid #00AAF1;
    background-color: transparent;
    color: #00AAF1
}

.linkedin:hover {
    color: #0177B5
}

.linkedin.border {
    border: 1px solid #0177B5;
    background-color: transparent;
    color: #0177B5
}

.youtube:hover {
    color: #DE2825;
}

.youtube.border {
    border: 1px solid #DE2825;
    background-color: transparent;
    color: #DE2825
}

.yahoo:hover {
    color: #4101AF;
}

.yahoo.border {
    border: 1px solid #4101AF;
    background-color: transparent;
    color: #4101AF
}

.rss:hover {
    color: #F90;
}

.rss.border {
    border: 1px solid #F90;
    background-color: transparent;
    color: #F90
}

.dropbox:hover {
    color: #007EE6;
}

.dropbox.border {
    border: 1px solid #007EE6;
    background-color: transparent;
    color: #007EE6
}

.soundcloud:hover {
    color: #FF6A22;
}

.soundcloud.border {
    border: 1px solid #FF6A22;
    background-color: transparent;
    color: #FF6A22
}

.vimeo:hover {
    color: #1BB6EC;
}

.vimeo.border {
    border: 1px solid #1BB6EC;
    background-color: transparent;
    color: #1BB6EC
}

.android:hover {
    color: #97C024;
}

.android.border {
    border: 1px solid #97C024;
    background-color: transparent;
    color: #97C024
}

.social-icon ul .inverse.round a {
    border-radius: 50px
}

.facebook.bg,
.facebook.border:hover,
.twitter.bg,
.twitter.border:hover,
.instagram.bg,
.instagram.border:hover,
.google.bg,
.google.border:hover,
.pinterest.bg,
.pinterest.border:hover,
.skype.bg,
.skype.border:hover,
.linkedin.bg,
.linkedin.border:hover,
.youtube.bg,
.youtube.border:hover,
.yahoo.bg,
.yahoo.border:hover,
.rss.bg,
.rss.border:hover,
.dropbox.bg,
.dropbox.border:hover,
.soundcloud.bg,
.soundcloud.border:hover,
.vimeo.bg,
.vimeo.border:hover,
.android.bg,
.android.border:hover {
    color: #ececec !important
}

.facebook.bg,
.facebook.border:hover {
    background-color: #3B579D;
}

.twitter.bg,
.twitter.border:hover {
    background-color: #2CAAE1;
}

.instagram.bg,
.instagram.border:hover {
    background-color: #5d4a3b;
}

.google.bg,
.google.border:hover {
    background-color: #DA4835;
}

.snapchat.bg,
.snapchat.border:hover {
    background-color: #fffc00;
}

.pinterest.bg,
.pinterest.border:hover {
    background-color: #c9181f;
}

.skype.bg,
.skype.border:hover {
    background-color: #00AAF1;
}

.linkedin.bg,
.linkedin.border:hover {
    background-color: #0177B5;
}

.youtube.bg,
.youtube.border:hover {
    background-color: #DE2825;
}

.yahoo.bg,
.yahoo.border:hover {
    background-color: #4101AF;
}

.rss.bg,
.rss.border:hover {
    background-color: #F90;
}

.dropbox.bg,
.dropbox.border:hover {
    background-color: #007EE6;
}

.soundcloud.bg,
.soundcloud.border:hover {
    background-color: #FF6A22;
}

.vimeo.bg,
.vimeo.border:hover {
    background-color: #1BB6EC;
}

.android.bg,
.android.border:hover {
    background-color: #97C024;
}

.facebook.bg:hover,
.twitter.bg:hover,
.instagram.bg:hover,
.google.bg:hover,
.pinterest.bg:hover,
.skype.bg:hover,
.linkedin.bg:hover,
.youtube.bg:hover,
.yahoo.bg:hover,
.rss.bg:hover,
.dropbox.bg:hover,
.soundcloud.bg:hover,
.vimeo.bg:hover,
.android.bg:hover {
    opacity: 0.7
}


/*****************************************************
******************  15. Counters  ********************
*****************************************************/

.fact-style-1 {
    position: relative
}

.fact-style-1 .fact-wrapper {
    display: inline-block;
    padding-left: 60px;
}

.fact-style-1 .fact-icons {
    position: absolute;
    font-size: 35px;
    left: 0;
    top: 30px;
    bottom: 0;
}

.counter {
    font-family: "TheSansArabic";
    font-size: 22px;
    letter-spacing: 4.5px;
    color: #3e3e3e;
    margin-top: 20px;
    margin-bottom: 10px;
}

.bg-dark .counter,
.parallax-overlay-dark .counter {
    color: #ececec
}

.counter-info {
    margin-bottom: 30px
}

.counter-info .counter {
    margin: 0;
    letter-spacing: 3px;
    margin-bottom: 5px
}

.counter-info i {
    display: inline-block
}

.counter-info .sub-tittle {
    font-weight: normal
}


.exhibition-info {
    margin-bottom: 30px
}

.exhibition-info .counter {
    margin: 0;
    letter-spacing: 3px;
    margin-bottom: 5px
}

.exhibition-info i {
    display: inline-block
}

.exhibition-info .sub-tittle {
    font-weight: normal;
    font-family: 'TheSansArabic', sans-serif;

}


/*****************************************************
***************  23. Call To Action ******************
*****************************************************/

.cta {
    padding: 50px 40px;
}

.cta h1,
.cta h2,
.cta h3,
.cta h4,
.cta h5,
.cta h6 {
    margin-top: auto;
    text-transform: none;
    letter-spacing: .2px;
    font-family: 'Open Sans'
}

.cta p {
    margin-bottom: auto
}


.cta .news-search {
    position: relative;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.cta .news-search input[type="text"] {
    border: 0px;
    border-bottom: 1px solid rgba(204, 204, 204, 0.5);
    background-color: transparent;
    padding: 10px 15px;
    width: 100%;
    font-family: "TheSansArabic", Helvetica, sans-serif;
}

.cta .news-search button {
    padding: 8px 20px;
    border: 0;
    right: 0px;
    bottom: 0px;
    transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    font-family: "TheSansArabic", Helvetica, sans-serif;

}


/*===============================
 =====  28.8 Footer Bottom  =====
*===============================*/


.footer-bottom {
    background-color: #323232;
    padding: 20px 0px;
    display: flex;
}

.footer-bottom-alt {
    background-color: transparent;
}

.footer-bottom.bg-white {
    background-color: #fff;
}

.footer-bottom.bg-gray {
    background-color: #f8f8f8;
}

.footer-bottom .copyright {
    color: #fff;
    margin: auto;
    margin-top: 16px;
    font-family: "TheSansArabic"
}

.footer-bottom .copyright a:hover {
    color: #fff
}

.footer-bottom.bg-gray .copyright a:hover,
.footer-bottom.bg-white .copyright a:hover {
    color: #212121
}

.footer-bottom .social-icon {
    padding: 5px 0px;
}

.footer-bottom .social-icon a i {
    padding: 10px;
}

.footer-bottom .social-icon a:hover i {
    background-color: #3e3e3e;
    color: #fff;
    border-radius: 5px
}


/*****************************************************
********************  29. Form ***********************
*****************************************************/

textarea.form-control {
    min-height: 150px
}

::-webkit-input-placeholder {
    font-weight: normal;
    font-size: 12px
}

button {
    font-weight: normal
}

.form-control {
    height: 40px;
    border-radius: 0px;
    border: 1px solid #ddd;
    box-shadow: none;
    font-size: 13px;
    background-color: transparent;
    font-weight: normal
}

.bg-dark .form-control {
    border: 1px solid #777;
}

label {
    font-weight: normal;
    font-family: "TheSansArabic", Helvetica, sans-serif;
    text-transform: uppercase;
    color: #3e3e3e;
    font-size: 12px;
    letter-spacing: 1px;
    margin-bottom: 10px
}


/*****************************************************
*****************   Back TO Top *******************
*****************************************************/

#back-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 9999;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 35px;
    background: rgba(0, 0, 0, .4);
    color: #ececec;
    font-size: 10px;
    cursor: pointer;
    border: 0;
    border-radius: 2px;
    text-decoration: none;
    transition: opacity 0.2s ease-out;
    -webkit-transition: opacity 0.2s ease-out;
    opacity: 0;
}

#back-to-top:hover {
    background: #3e3e3e;
}

#back-to-top.show {
    opacity: 1;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: "100%";
    /*height: 30px;*/
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    /*top: 51px;*/
    width: '100%';
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    /*max-height: 100px;*/
    overflow-y: auto;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
